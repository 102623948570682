import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutUs/index.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/index.vue')
  },
  {
    path: '/news/detail',
    name: 'NewsDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/detail.vue')
  },
  {
    path: '/customer',
    name: 'Customer',
    component: () => import(/* webpackChunkName: "about" */ '../views/customer/index.vue')
  },
  {
    path: '/customer/detail',
    name: 'Customer',
    component: () => import(/* webpackChunkName: "about" */ '../views/customer/detail.vue')
  },
  {
    path: '/mf',
    name: 'Mf',
    component: () => import(/* webpackChunkName: "about" */ '../views/mes/mf.vue')
  },
  {
    path: '/zz',
    name: 'Zz',
    component: () => import(/* webpackChunkName: "about" */ '../views/mes/zz.vue')
  },
  {
    path: '/bx',
    name: 'Bx',
    component: () => import(/* webpackChunkName: "about" */ '../views/mes/bx.vue')
  },
  {
    path: '/wfb',
    name: 'Wfb',
    component: () => import(/* webpackChunkName: "about" */ '../views/mes/wfb.vue')
  },
  {
    path: '/yr',
    name: 'Yr',
    component: () => import(/* webpackChunkName: "about" */ '../views/mes/yr.vue')
  },
  {
    path: '/xc',
    name: 'Xc',
    component: () => import(/* webpackChunkName: "about" */ '../views/media/xc.vue')
  },
  {
    path: '/vr',
    name: 'Vr',
    component: () => import(/* webpackChunkName: "about" */ '../views/media/vr.vue')
  },
  {
    path: '/vr/detail',
    name: 'Vrdetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/media/vrDetail.vue')
  },
  {
    path: '/3d',
    name: '3d',
    component: () => import(/* webpackChunkName: "about" */ '../views/media/3d.vue')
  },
  {
    path: '/xn',
    name: 'Xn',
    component: () => import(/* webpackChunkName: "about" */ '../views/media/xn.vue')
  },
  {
    path: '/videoPlay',
    name: 'VideoPlay',
    component: () => import(/* webpackChunkName: "about" */ '../views/videoPlay/index.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

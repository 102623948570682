
(function () {
    changePage()
})()
function changePage () {
    var initFontSize = 16
    var webWidth = 1920
    var clientWidth = window.document.documentElement.clientWidth || webWidth
    var newFontSize = initFontSize * (clientWidth / webWidth)
    document.documentElement.style.fontSize = newFontSize + 'px'
}
window.onresize = function () { changePage() };

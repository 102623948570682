<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/public/header.vue'
import Footer from '@/components/public/footer.vue'
export default {
  components: {
    Header, Footer
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  }
}
</script>

<style lang="less">
html, body {
  margin: 0;
  padding: 0;
  color: #333333;
}

h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.underline {
  width: 60px;
  height: 6px;
  background: #1676FF;
  margin-bottom: 48px;
}

.underline1 {
  width: 60px;
  height: 6px;
  background: #ffffff;
  margin-bottom: 48px;
}

</style>
